import { BlockStack } from '@shopify/polaris';
import { useClientside } from 'src/hooks/use-clientside';
import { useGlobalState } from 'src/hooks/use-global-state';
import { LoadingOwly } from './LoadingOwly';
import { StyledContainer } from './styled';

type Props = {
  text?: string;
};

export const LoadingScreen = ({
  text = 'Getting your page ready...',
}: Props) => {
  const {
    state: { isAuthenticating },
  } = useGlobalState('user');
  const isClientSide = useClientside();

  if (isAuthenticating && isClientSide) {
    return (
      <StyledContainer data-test='loading-dashboard'>
        <BlockStack align='center' inlineAlign='center'>
          <LoadingOwly />
          <br />
          <p>{text}</p>
        </BlockStack>
      </StyledContainer>
    );
  }

  return null;
};
